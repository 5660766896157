import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Col, Image, Row } from "react-bootstrap";
import Link from "next/link";
import { useRouter } from "next/router";
import { Spinner } from "react-bootstrap";
import Toast from "../Toast";
import { toast } from "react-toastify";
import "./loginPage.css";
import "./../ContactUs/ContactForm/ContactForm.css";
import "../../app/globals.css";
import Head from "next/head";
import { useAuth } from "@/context/AuthContext";

const LoginPage = React.memo(({ show }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  var payload = {};

  const handleLoginbtn = async () => {
    setLoading(true);
    try {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(email)) {
        setLoading(false);
        toast.error("Please enter a valid email address.");
        return;
      }

      if (email === "" || password === "") {
        setLoading(false);
        toast.error("Please enter both your email address and password.");
        return;
      }
      payload.email = email;
      payload.password = password;
      const user = await login(payload);
      if (user && user.email !== "") {
        setLoading(false);
        toast.success("You have successfully logged in.");

        const redirectTo = router.query.redirect || "/";
        router.push(redirectTo);
      } else {
        toast.error("Please check your email and password and try again.");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Please check your email and password and try again.");
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Toast />
      <Head>
        <title>Login - KingSurplus</title>
        <meta name="description" content="Welcome To KingSurplus Login Page" />
        <link rel="canonical" href={`https://kingsurplus.com/login`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Login - KingSurplus" />
        <meta
          property="og:description"
          content="Welcome To Kingsurplus Login Page"
        />
        <meta
          property="og:image"
          content="https://kingsurplus.com/assets/images/logo.webp"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:url" content="https://kingsurplus.com/login" />
        <meta property="og:site_name" content="KingSurplus" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Login - KingSurplus" />
        <meta
          name="twitter:description"
          content="Welcome To KingSurplus Login Page"
        />
        <meta
          name="twitter:image"
          content="https://kingsurplus.com/assets/images/logo.webp"
        />
        <meta name="twitter:url" content="https://kingsurplus.com/login" />
      </Head>
      <div className="main-login-wrap">
        <Row className="m-0 bg-light-gray">
          <Col lg={6} className="bg-white">
            <div className="logoInfo">
              <div className="login-logo">
                <Image src="/assets/images/logo.webp" />
              </div>
              <p>
                "A good name is more desirable than great riches; to be esteemed
                is better than silver or gold." - Proverbs 22:1{" "}
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="login-box">
              <h3>Welcome Back </h3>
              <form onSubmit={handleLoginbtn}>
                <div className="login-input-width">
                  <div className="form-input">
                    <label>Enter Your Email</label>
                    <input
                      type="email"
                      name={"email"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-input">
                    <label>Enter Your Password</label>
                    <div className="input-icon">
                      <input
                        type={showPassword ? "text" : "password"}
                        name={"password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <a onClick={togglePasswordVisibility}>
                        {showPassword ? (
                          <Icon
                            icon="ant-design:eye-filled"
                            style={{ color: "black" }}
                          />
                        ) : (
                          <Icon icon="mdi:eye-off" style={{ color: "black" }} />
                        )}
                      </a>
                    </div>
                  </div>
                  <Link
                    className="forgot-password-link"
                    href="/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                  {loading ? (
                    <button
                      className="btn btn-blue d-block w-100 btn-sign"
                      disabled
                      style={{
                        position: "relative",
                        height: "40px !important",
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                          color: "white",
                          top: "calc(50% - 12px)",
                          left: "calc(50% - 12px)",
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-blue d-block w-100 btn-sign login-btn"
                      onClick={handleLoginbtn}
                    >
                      Sign In
                    </button>
                  )}
                </div>

                <h6 className="signup-link">
                  Don’t have an account?<Link href="/sign-up"> Sign up</Link>{" "}
                </h6>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
  // }
});

export default LoginPage;
