import LoginPage from "../../components/LoginPage/LoginPage";
import "../../app/globals.css";

function Login() {
  return (
    <>
      <LoginPage />
    </>
  );
}

export default Login;
